<template>
  <div
    class="code-getter"
    :class="{ active: countDown > 0 }"
    @click="sendCode()"
  >
    {{ countDown > 0 ? `${countDown}S后重新获取` : "重新发送" }}
  </div>
</template>

<script>
import { getVerifyCode } from "@/api/login";
export default {
  props: {
    phoneNumber: {
      type: String,
      default: ""
    },
    type: [String, Number]
  },
  data() {
    return {
      timer: null,
      countDown: 0
    };
  },
  methods: {
    start() {
      this.countDown = 59;
      this.timer = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async sendCode(type = this.type) {
      if (/^1\d{10}$/.test(this.phoneNumber)) {
        this.start();
        const res = await getVerifyCode({
          type,
          cellphone: this.phoneNumber
        });
        if (!res) {
          this.countDown = 0;
          clearInterval(this.timer);
        }
      } else {
        this.$showError("手机号码格式不正确");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.code-getter {
  color: $primary;
  cursor: pointer;
  &.active {
    color: #666;
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
  }
}
</style>
