var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "code-input-dialog",
    attrs: {
      "title": "短信验证",
      "width": "500px",
      "top": "calc((100vh - 280px) / 2)"
    },
    on: {
      "open": _vm.handleOpen
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('p', {
    staticClass: "tips"
  }, [_vm._v(" 已发送当前登录手机号：" + _vm._s(_vm._f("formatPhone")(_vm.$userInfo.cellphone, 2)) + " ")]), _c('f-code-input', {
    ref: "codeInput",
    attrs: {
      "border": ""
    },
    model: {
      value: _vm.codeValue,
      callback: function callback($$v) {
        _vm.codeValue = $$v;
      },
      expression: "codeValue"
    }
  }), _c('br'), _c('f-code-getter', {
    ref: "codeGetter",
    attrs: {
      "phoneNumber": _vm.$userInfo.cellphone
    }
  }), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:visible');
      }
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": _vm.codeValue.length < 6
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("确定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }