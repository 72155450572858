var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group",
    class: {
      border: _vm.border
    }
  }, [_vm._l(_vm.codeValues, function (item, index) {
    return _c('el-input', {
      key: index,
      ref: "codeInput",
      refInFor: true,
      attrs: {
        "maxlength": "1",
        "value": item.value
      },
      nativeOn: {
        "keydown": function keydown($event) {
          return _vm.handleKeyup($event, index);
        }
      }
    });
  }), _c('div', {
    staticClass: "mask",
    on: {
      "click": _vm.handleMaskClick
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }