var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "title": "以下图片未通过安全校验，请替换",
      "width": "700px"
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('ul', _vm._l(_vm.list, function (item) {
    return _c('li', {
      key: item
    }, [_c('el-image', {
      attrs: {
        "fit": "cover",
        "preview-src-list": _vm.list,
        "src": item
      }
    })], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }