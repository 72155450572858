var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "close-on-click-modal": false,
      "title": "添加成员",
      "width": "780px"
    },
    on: {
      "open": _vm.open
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('el-input', {
    staticClass: "input-with-select",
    attrs: {
      "placeholder": "团队名称",
      "clearable": ""
    },
    on: {
      "input": _vm.searchMemberList
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('i', {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      "slot": "prefix"
    },
    slot: "prefix"
  })]), _c('el-collapse', {
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.dataList, function (item, index) {
    return _c('el-collapse-item', {
      key: index,
      attrs: {
        "name": index
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('el-checkbox', {
      attrs: {
        "disabled": item.disabled,
        "value": !!_vm.result.find(function (v) {
          return v.companyId === item.companyId;
        })
      },
      on: {
        "change": function change(checked) {
          return _vm.handleCheck(checked, item);
        }
      }
    }, [_vm._v(_vm._s(item.companyName))])], 1), _vm._l(item.children, function (val) {
      return _c('el-checkbox', {
        key: val.companyId,
        attrs: {
          "disabled": val.disabled,
          "value": !!_vm.result.find(function (v) {
            return v.companyId === val.companyId;
          })
        },
        on: {
          "change": function change(checked) {
            return _vm.handleCheck(checked, val, item);
          }
        }
      }, [_vm._v(" " + _vm._s(val.companyName) + " ")]);
    })], 2);
  }), 1)], 1), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" 已选：" + _vm._s(_vm.selectCount.memberCount) + "个团队，" + _vm._s(_vm.selectCount.userCount) + "位用户 "), _c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function click($event) {
        _vm.rightArr = [];
      }
    }
  }, [_vm._v("清空")])], 1), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "list"
  }, _vm._l(_vm.rightArr, function (item) {
    var _item$children;
    return _c('li', {
      key: item.companyId
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(item.companyName) + " "), item.companyId == -100 ? _c('span', [_vm._v(" （" + _vm._s(((_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length) || 0) + "个团队，" + _vm._s(item.userCount) + "位用户） ")]) : item.departmentType == 3 ? _c('span', [_vm._v("（独立经纪）")]) : _c('span', [_vm._v("（" + _vm._s(item.userCount) + "）")])]), _c('i', {
      staticClass: "el-icon-close",
      on: {
        "click": function click($event) {
          return _vm.handleCheck(false, item);
        }
      }
    })]);
  }), 0)])])]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:visible', false);
      }
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("确定")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }