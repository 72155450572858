<template>
  <el-dialog
    :close-on-click-modal="false"
    v-bind="$attrs"
    v-on="$listeners"
    title="添加成员"
    width="780px"
    @open="open"
  >
    <div class="wrapper">
      <div class="left">
        <el-input
          placeholder="团队名称"
          v-model="queryData.keyword"
          class="input-with-select"
          clearable
          @input="searchMemberList"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-collapse v-model="activeNames">
          <el-collapse-item
            :name="index"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <template slot="title">
              <el-checkbox
                :disabled="item.disabled"
                :value="!!result.find(v => v.companyId === item.companyId)"
                @change="checked => handleCheck(checked, item)"
                >{{ item.companyName }}</el-checkbox
              >
            </template>
            <el-checkbox
              v-for="val in item.children"
              :key="val.companyId"
              :disabled="val.disabled"
              :value="!!result.find(v => v.companyId === val.companyId)"
              @change="checked => handleCheck(checked, val, item)"
            >
              {{ val.companyName }}
            </el-checkbox>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="right">
        <div class="title">
          已选：{{ selectCount.memberCount }}个团队，{{
            selectCount.userCount
          }}位用户
          <el-button type="text" @click="rightArr = []">清空</el-button>
        </div>
        <div class="scroller">
          <ul class="list">
            <li v-for="item in rightArr" :key="item.companyId">
              <div class="label">
                {{ item.companyName }}
                <span v-if="item.companyId == -100">
                  （{{ item.children?.length || 0 }}个团队，{{
                    item.userCount
                  }}位用户）
                </span>
                <span v-else-if="item.departmentType == 3">（独立经纪）</span>
                <span v-else>（{{ item.userCount }}）</span>
              </div>
              <i class="el-icon-close" @click="handleCheck(false, item)"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="$emit('update:visible', false)">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getMemberList } from "@/api/message";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    //已选中
    memberArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      queryData: {
        keyword: ""
      },
      activeNames: [0, 1],
      dataList: [], //已过滤的成员列表
      allDataList: [], //未过滤的全部成员
      result: [], //全部已选中数据
      rightArr: [] //目前弹框所选数据
    };
  },
  computed: {
    //已选团队和用户数量
    selectCount() {
      let memberCount = 0;
      let filter = this.rightArr.filter(item => item.companyId == -100);
      //选择了全部企业则直接取children的长度
      if (filter.length) {
        memberCount = filter[0].children?.length;
      } else {
        //选择了单个企业则取选择的数量
        memberCount = this.rightArr.filter(item => item.departmentType == 2)
          .length;
      }
      let userCount = this.rightArr.reduce((total, item) => {
        return total + item.userCount * 1;
      }, 0);
      return { memberCount: memberCount || 0, userCount: userCount || 0 };
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    //弹框打开
    async open() {
      const handleArr = arr => {
        arr.forEach(item => {
          this.$set(
            item,
            "disabled",
            this.memberArr.map(m => m.companyId).includes(item.companyId)
          );
          if (item.children) {
            handleArr(item.children);
          }
        });
      };
      handleArr(this.dataList);
      this.result = this.memberArr;
      this.rightArr = [];
    },
    // 团队列表
    async getList() {
      const res = await getMemberList(this.queryData);
      if (res) {
        this.dataList = res.dataList;
        this.allDataList = res.dataList;
      }
    },
    //筛选团队列表
    searchMemberList() {
      let newArr = [];
      this.$deepClone(this.allDataList).forEach((item, index) => {
        newArr[index] = item;
        newArr[index].children = item.children.filter(
          val => val.companyName.indexOf(this.queryData.keyword) > -1
        );
      });
      this.dataList = newArr.filter(item => item.children.length);
    },
    //单选
    handleCheck(checked, item, parent) {
      if (checked) {
        if (item.children?.length) {
          item.children.forEach(child => {
            this.$set(child, "disabled", false);
            this.result = this.result.filter(
              v => v.companyId !== child.companyId
            );
          });
        } else {
          this.$set(parent, "disabled", false);
          this.result = this.result.filter(
            v => v.companyId !== parent.companyId
          );
        }
        this.result = [...this.result, item];
      } else {
        this.result = this.result.filter(v => v.companyId !== item.companyId);
      }
      //过滤掉已添加的数据
      this.rightArr = this.result.filter(
        v => !this.memberArr.map(m => m.companyId).includes(v.companyId)
      );
    },
    // 添加成员
    async confirm() {
      let result = this.result.map(item => {
        return {
          companyId: item.companyId,
          companyName: item.companyName,
          companyType: item.companyId == -100 ? 2 : 3
        };
      });
      this.$emit("success", result);
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  display: flex;
  .left,
  .right {
    flex: 1;
    height: 340px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .right {
    border-left: 1px solid #d7d7d7;
    .title {
      @include flex-ycb;
      height: 40px;
      line-height: 40px;
      color: #333;
      .clear {
        color: #409eff;
        cursor: pointer;
        &:hover {
          opacity: 0.75;
        }
      }
    }
    .scroller {
      flex: 1;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .list {
      margin-top: 20px;
      li {
        @include flex-ycb;
        height: 19px;
        line-height: 19px;
        color: #333;
        i {
          font-size: 20px;
          cursor: pointer;
          color: #aaa;
          &:hover {
            opacity: 0.75;
          }
        }
      }
      li + li {
        margin-top: 20px;
      }
    }
  }
}
::v-deep {
  .el-checkbox {
    display: block;
    height: 36px;
    line-height: 36px;
  }
  .el-collapse {
    border: none;
    margin-top: 10px;
    flex: 1;
    overflow-y: auto;
    .el-collapse-item__wrap {
      border-bottom: none;
      margin-left: 25px;
    }
    .el-collapse-item__header {
      height: 40px;
      line-height: 40px;
      border-bottom: none;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
  }
  .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  .el-dialog__body {
    padding-bottom: 15px;
  }
}
</style>
