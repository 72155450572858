var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "foot-bar"
  }, [_c('div', {
    staticClass: "word-count"
  }, [typeof _vm.wordCount === 'number' ? [_vm._v(" 正文字数 " + _vm._s(_vm.wordCount) + " ")] : _vm._e()], 2), _c('div', {
    staticClass: "actions"
  }, [_vm.formData.status != 1 ? _c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.confirm(2);
      }
    }
  }, [_vm._v("保存为草稿")]) : _vm._e(), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.handlePublishClick
    }
  }, [_vm._v("发表")])], 1)]), _c('c-code-input-dialog', {
    attrs: {
      "visible": _vm.codeInputDialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.codeInputDialogVisible = $event;
      },
      "success": function success(code) {
        return _vm.confirm(1, code);
      }
    }
  }), _c('c-invalid-image-dialog', {
    attrs: {
      "visible": _vm.invalidImageDialogVisible,
      "list": _vm.invalidImageList
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.invalidImageDialogVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }