<template>
  <div class="input-group" :class="{ border }">
    <el-input
      v-for="(item, index) of codeValues"
      :key="index"
      ref="codeInput"
      maxlength="1"
      :value="item.value"
      @keydown.native="handleKeyup($event, index)"
    />
    <div class="mask" @click="handleMaskClick"></div>
  </div>
</template>

<script>
export default {
  props: {
    border: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focusIndex: 0,
      codeValues: []
    };
  },
  created() {
    this.codeValues = new Array(6).fill().map(() => ({ value: "" }));
  },
  mounted() {
    this.$refs.codeInput[0].focus();
  },
  methods: {
    // 键盘抬起的时候执行赋值或者删除操作
    handleKeyup({ key }, index) {
      const currentValue = this.codeValues[index].value;
      // 如果按的是数字 则进行赋值
      try {
        if (/^\d$/.test(key)) {
          // 如果当前输入框存在值  直接给下一个输入框赋值
          if (currentValue) {
            this.codeValues[index + 1].value = key;
            this.focusIndex = index + 2;
            // 否则跟当前输入框赋值
          } else {
            this.codeValues[index].value = key;
            this.focusIndex = index + 1;
          }
          // 如果按是删除键 则进行回删
        } else if (key === "Backspace") {
          // 如果当前输入框存在值  直接删除当前值
          if (currentValue) {
            this.focusIndex = index - 1;
            this.codeValues[index].value = "";
            // 否则删除前一个输入框的值
          } else {
            this.focusIndex = index - 2;
            this.codeValues[index - 1].value = "";
          }
        }
      } catch {
        //
      }
      // 边界处理
      if (this.focusIndex === this.codeValues.length) {
        this.focusIndex = this.codeValues.length - 1;
      } else if (this.focusIndex < 0) {
        this.focusIndex = 0;
      }
      this.$refs.codeInput[this.focusIndex].focus();
      this.$emit("input", this.codeValues.map(item => item.value).join(""));
    },
    handleMaskClick() {
      for (let i = 0; i < this.codeValues.length; i++) {
        if (!this.codeValues[i].value) {
          this.focusIndex = i;
          this.$refs.codeInput[i].focus();
          break;
        }
        // 如果都填了 则让最后一个input获得焦点
        if (i === this.codeValues.length - 1) {
          this.$refs.codeInput[i].focus();
        }
      }
    },
    getValue() {
      return this.codeValues.map(item => item.value).join("");
    }
  }
};
</script>

<style scoped lang="scss">
.input-group {
  display: flex;
  justify-content: space-between;
  position: relative;
  &.border {
    justify-content: flex-start;

    ::v-deep {
      .el-input {
        width: 50px;
        & + .el-input {
          margin-left: 10px;
        }
        .el-input__inner {
          height: 50px;
          border: 1px solid #eee;
        }
      }
    }
  }
  .mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    cursor: text;
  }
  ::v-deep {
    .el-input {
      width: 10%;
      .el-input__inner {
        padding: 0;
        height: 40px;
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        border: none;
        border-radius: 0;
        color: #141414;
        caret-color: red;
        border-bottom: 1px solid #333;
      }
    }
  }
}
</style>
