<template>
  <el-dialog
    title="短信验证"
    width="500px"
    class="code-input-dialog"
    top="calc((100vh - 280px) / 2)"
    v-bind="$attrs"
    v-on="$listeners"
    @open="handleOpen"
  >
    <p class="tips">
      已发送当前登录手机号：{{ $userInfo.cellphone | formatPhone(2) }}
    </p>
    <f-code-input border ref="codeInput" v-model="codeValue"></f-code-input>
    <br />
    <f-code-getter
      :phoneNumber="$userInfo.cellphone"
      ref="codeGetter"
    ></f-code-getter>
    <div slot="footer">
      <el-button @click="$emit('update:visible')">取消</el-button>
      <el-button
        type="primary"
        @click="confirm"
        :disabled="codeValue.length < 6"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import FCodeInput from "@/components/code-input";
import FCodeGetter from "@/components/code-getter";
export default {
  components: {
    FCodeInput,
    FCodeGetter
  },
  data() {
    return {
      codeValue: ""
    };
  },
  methods: {
    confirm() {
      if (this.codeValue.length === 6) {
        this.$emit("success", this.codeValue);
      }
    },
    handleOpen() {
      this.$nextTick(() => {
        this.$refs.codeGetter.sendCode();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.tips {
  color: #999;
  margin-bottom: 10px;
}
</style>
