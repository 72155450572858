var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "code-getter",
    class: {
      active: _vm.countDown > 0
    },
    on: {
      "click": function click($event) {
        return _vm.sendCode();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.countDown > 0 ? "".concat(_vm.countDown, "S\u540E\u91CD\u65B0\u83B7\u53D6") : "重新发送") + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }