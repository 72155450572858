<template>
  <el-dialog
    title="以下图片未通过安全校验，请替换"
    width="700px"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <ul>
      <li v-for="item of list" :key="item">
        <el-image fit="cover" :preview-src-list="list" :src="item"></el-image>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
ul {
  overflow: hidden;
  li {
    float: left;
    margin: 10px;
    border: 1px solid #eee;
    ::v-deep {
      .el-image {
        width: 198px;
        height: 100px;
      }
    }
  }
}
</style>
