<template>
  <div>
    <div class="foot-bar">
      <div class="word-count">
        <template v-if="typeof wordCount === 'number'">
          正文字数 {{ wordCount }}
        </template>
      </div>
      <div class="actions">
        <!-- 这个预览文章并没有提交，所以毫无意义，跟产品沟通已取消掉 -->
        <!-- <el-button>预览</el-button> -->
        <el-button @click="confirm(2)" v-if="formData.status != 1"
          >保存为草稿</el-button
        >
        <el-button type="primary" @click="handlePublishClick">发表</el-button>
      </div>
    </div>
    <c-code-input-dialog
      :visible.sync="codeInputDialogVisible"
      @success="code => confirm(1, code)"
    ></c-code-input-dialog>
    <c-invalid-image-dialog
      :visible.sync="invalidImageDialogVisible"
      :list="invalidImageList"
    ></c-invalid-image-dialog>
  </div>
</template>

<script>
import CCodeInputDialog from "../../_components/codeInputDialog";
import CInvalidImageDialog from "./_components/invalidImage";
import {
  createMassMessage,
  editMassMessage,
  editMassMessageRecord
} from "@/api/message";
import { checkImgNWord } from "@/api/common";
import { showLoading, hideLoading } from "@/utils";
export default {
  components: {
    CCodeInputDialog,
    CInvalidImageDialog
  },
  props: {
    // 正文字数
    wordCount: Number,
    // 父级 用于操作前的验证方法
    beforeSubmit: {
      type: Function,
      default: () => {}
    },
    // 表单数据
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      submitLoading: false,
      codeInputDialogVisible: false, //显示验证码输入框
      invalidImageDialogVisible: false,
      invalidImageList: [] //不合法的图片
    };
  },
  methods: {
    //校验发表设置
    validateSetForm() {
      const {
        dataType,
        groupMass,
        regularMemberList,
        regularMass,
        regularDate
      } = this.formData;
      let msg;
      if (dataType == 1) {
        if (groupMass && !regularMemberList.length) {
          msg = "请添加群发成员";
        } else if (regularMass && !regularDate) {
          msg = "请选择群发时间";
        }
      }
      if (msg) {
        this.$showError(msg);
        return false;
      }
      return true;
    },
    // 校验图文合法性
    async checkImgNWord() {
      const { cover, shareCover, context, title, summary } = this.formData;
      // 内容中的图片节点
      const imgs = context?.match(/<img[^<>]+>/gi);
      // 获取图片节点的图片路径
      const imgSrcs =
        imgs?.map(item => {
          return item.match(/src="([^"]+)/g)[0].slice(5);
        }) || [];
      const Images = [cover, shareCover, ...imgSrcs].filter(item => item);
      const checkList = [
        ...[title || "", summary || "", context || ""].map(item => ({
          keyword: item,
          type: 1 // 文字1
        })),
        ...Images.map(item => ({
          // 指定图片尺寸 防止超过1M无法验证
          keyword: item + `?x-oss-process=image/resize,m_lfit,h_1000,w_1000`,
          type: 2 // 图片2
        }))
      ];
      const checkRes = await checkImgNWord({ checkList });
      if (checkRes.code !== 0) {
        const { index: invalidIndex } = checkRes.data || {};
        const invalidItem = checkList[invalidIndex];
        // 找到不合法的图片
        if (invalidItem?.type === 2) {
          // 显示不合法的图片
          this.invalidImageDialogVisible = true;
          this.invalidImageList = [invalidItem.keyword];
          return false;
        }
        // 提示不合法的文字
        let msg;
        if (invalidIndex > -1) {
          // 图文消息才有 title summary
          if (invalidIndex == 0 && title) {
            msg = "标题包含不合法文字";
          } else if (invalidIndex == 1 && summary) {
            msg = "摘要包含不合法文字";
          } else {
            msg = "内容包含不合法文字";
          }
          this.$showError(msg);
          return false;
        }
      }
      return true;
    },
    // 点击发表
    async handlePublishClick() {
      const valid = await this.beforeSubmit();
      // 校验表单是否填写完毕
      if (!valid || !this.validateSetForm()) return;
      // 校验图文合法性
      const validGraphic = await this.checkImgNWord();
      if (!validGraphic) return;
      // 非群发
      if (this.formData.dataType === 2) {
        // 呼起验证码输入框 并 发送验证码
        this.codeInputDialogVisible = true;
        return;
      }
      // 群发提示
      this.$confirm("消息开始群发后，无法编辑，无法撤销!", "群发消息", {
        confirmButtonText: "继续群发",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 呼起验证码输入框 并 发送验证码
          this.codeInputDialogVisible = true;
        })
        .catch(() => {});
    },
    // 确认发表
    async confirm(status, verificationCode) {
      // 存为草稿
      if (status === 2) {
        const valid = await this.beforeSubmit();
        // 校验表单是否填写完毕
        if (!valid || !this.validateSetForm()) return;
        // 校验图文合法性
        const validGraphic = await this.checkImgNWord();
        if (!validGraphic) return;
      }
      // 提交数据 【新增 草稿编辑 已发表的编辑 接口不一样】
      let fn = createMassMessage;
      if (this.formData.id) {
        // 已发表的
        if (this.formData.status === 1) {
          fn = editMassMessageRecord;
        } else {
          fn = editMassMessage;
        }
      }
      const res = await fn({
        ...this.formData,
        status,
        code: verificationCode,
        cellphone: this.$userInfo.cellphone
      });
      if (res) {
        this.codeInputDialogVisible = false;
        this.$confirm("提交成功，是否关闭当前页面？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success"
        })
          .then(() => {
            // 关闭窗口
            window.close();
          })
          .catch(() => {
            // 告诉父级重置表单
            this.$emit("reset");
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.word-count {
  color: #999;
}
.code-input-dialog {
  ::v-deep {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
}
.foot-bar {
  padding: 20px;
  margin-top: 50px;
  background: #fff;
  box-shadow: 0 0 5px #eee;
  @include flex-ycb;
}
</style>
