<template>
  <el-form>
    <div class="fs17">发表设置</div>
    <el-radio-group v-model="formData.dataType">
      <el-radio-button :label="1">
        <div>
          <div>群发</div>
          <div>推送给用户，占用群发次数</div>
        </div>
        <i class="el-icon-check"></i>
      </el-radio-button>
      <el-radio-button :label="2">
        <div>
          <div>发布</div>
          <div>不推送，不占用群发次数</div>
        </div>
        <i class="el-icon-check"></i>
      </el-radio-button>
    </el-radio-group>
    <template v-if="formData.dataType == 1">
      <div class="switch">
        <label>分组群发：</label>
        <div>
          <el-switch v-model="groupMass"></el-switch>
          <div class="group-checked" v-if="formData.groupMass">
            <div
              class="checked-li"
              v-for="(item, index) in formData.regularMemberList"
              :key="index"
            >
              {{ item.companyName }}
              <i class="el-icon-error" @click="deleteMember(index)"></i>
            </div>
            <div class="add-btn" @click="showAddMemberDialog = true">添加</div>
          </div>
        </div>
      </div>
      <div class="switch">
        <label>定时群发：</label>
        <div>
          <el-switch v-model="regularMass"></el-switch>
          <template v-if="formData.regularMass">
            <p class="color9 tips">
              你可以选择5分钟后的今、明两天内任意时刻定时群发，成功设置后不支持修改，但在设定的时间之前可取消，取消后不占用群发条数
            </p>
            <div class="f-start mt20">
              <el-form-item class="mb16" :error="dayError">
                <el-select
                  v-model="day"
                  placeholder="请选择"
                  class="mr20"
                  @change="regularDate = ''"
                >
                  <el-option label="今天" :value="1"></el-option>
                  <el-option label="明天" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-time-picker
                v-model="regularDate"
                value-format="HH:mm:00"
                format="HH:mm"
                placeholder="选择时间"
                :picker-options="pickerOptions"
                :default-value="defaultValue"
              >
              </el-time-picker>
            </div>
          </template>
          <p class="color9">
            {{ day == 1 ? "今天" : "明天" }}还可以群发
            <span
              class="blue"
              :class="{
                red:
                  (day == 1 && !massCountObj.todayNumber) ||
                  (day == 2 && !massCountObj.tomorrowNumber)
              }"
            >
              {{
                day == 1
                  ? massCountObj.todayNumber
                  : massCountObj.tomorrowNumber
              }}
            </span>
            次
          </p>
        </div>
      </div>
    </template>
    <!--添加成员-->
    <add-member
      ref="addMember"
      :visible.sync="showAddMemberDialog"
      :memberArr="formData.regularMemberList"
      @success="handleAddMember"
    ></add-member>
  </el-form>
</template>

<script>
import addMember from "./_components/addMember";
import { getMassNumber } from "@/api/message";
import moment from "moment";
export default {
  components: { addMember },
  data() {
    return {
      showAddMemberDialog: false,
      formData: {
        dataType: 1,
        groupMass: 0,
        regularMemberList: [],
        regularMass: 0,
        regularDate: ""
      },
      groupMass: false, //分组群发
      regularMass: false, //定时群发
      day: 1, //定时群发日期
      regularDate: "", //定时群发时间
      massCountObj: {} //定时群发次数
    };
  },
  props: {
    detailForm: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    detailForm: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val?.id) {
          let {
            dataType,
            groupMass,
            regularMemberList,
            regularMass,
            regularDate
          } = val;
          this.formData.dataType = dataType;
          this.groupMass = groupMass == 1 ? true : false;
          this.formData.regularMemberList = regularMemberList || [];
          this.regularMass = regularMass == 1 ? true : false;
          if (regularDate) {
            const handleDay = d => {
              // 今天
              if (moment(d).isSame(moment(), "day")) {
                return 1;
              }
              // 明天
              if (moment(d).isSame(moment().add(1, "day"), "day")) {
                return 2;
              }
              return 1;
            };
            this.day = handleDay(regularDate);
            this.regularDate = moment(regularDate).format("HH:mm:00");
          }
        }
      }
    },
    //分组群发
    groupMass(val) {
      this.formData.groupMass = val ? 1 : 0;
    },
    //定时群发
    regularMass(val) {
      this.formData.regularMass = val ? 1 : 0;
    },
    //发送时间
    regularDate: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.formData.regularDate =
            this.day == 1
              ? moment().format("YYYY-MM-DD") + " " + val
              : moment()
                  .add(1, "day")
                  .format("YYYY-MM-DD") +
                " " +
                val;
        } else {
          this.formData.regularDate = "";
        }
      }
    }
  },
  computed: {
    //当天无可群发次数
    dayError() {
      return (this.day == 1 && !this.massCountObj.todayNumber) ||
        (this.day == 2 && !this.massCountObj.tomorrowNumber)
        ? "无剩余次数"
        : "";
    },
    //当前之前时间不可选
    pickerOptions() {
      return {
        selectableRange: (() => {
          let data = new Date();
          data.setMinutes(data.getMinutes() + 5);
          let hour = data.getHours();
          let minute = data.getMinutes();
          let second = data.getSeconds();
          return this.day == 1
            ? [`${hour}:${minute}:${second} - 23:59:59`]
            : ["00:00:00 - 23:59:59 "];
        })()
      };
    },
    //时间选择器打开时默认显示的时间
    defaultValue() {
      let data = new Date();
      data.setMinutes(data.getMinutes() + 5);
      return data;
    }
  },
  created() {
    this.getRecordCount();
  },
  methods: {
    //获取发送次数
    async getRecordCount() {
      const res = await getMassNumber();
      if (res) {
        this.massCountObj = res;
      }
    },
    //添加成员
    handleAddMember(e) {
      this.formData.regularMemberList = e;
    },
    //添加成员
    deleteMember(index) {
      this.formData.regularMemberList.splice(index, 1);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-radio-group {
  width: 100%;
  margin: 20px 0 30px;
  .el-radio-button {
    width: 50%;
    .el-radio-button__inner {
      width: 100%;
      border: none;
      background-color: #f2f2f2;
      text-align: left;
      box-shadow: none;
      padding: 16px 20px;
      @include flex-ycb;
      & > div:first-child {
        div:first-child {
          font-size: 15px;
          color: #333;
          margin-bottom: 6px;
        }
        div:last-child {
          color: #7f7f7f;
        }
      }
      i {
        font-weight: bold;
        display: none;
      }
    }
    &.is-active .el-radio-button__inner {
      background-color: #ecf0ff;
      & > div:first-child {
        div:first-child {
          color: $blue;
        }
        div:last-child {
          color: #82a7fd;
        }
      }
      i {
        display: block;
      }
    }
  }
}
.switch {
  display: flex;
  color: #666;
  margin-bottom: 22px;
  & > div {
    flex: 1;
    .el-switch {
      margin-bottom: 16px;
    }
    .group-checked {
      border: solid 1px #dcdfe6;
      padding: 6px 6px 0;
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      & > div {
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 2px;
        background: #f2f2f2;
        padding: 0 20px 0 14px;
      }
      .checked-li {
        margin-right: 6px;
        margin-bottom: 6px;
        position: relative;
        i {
          display: none;
          position: absolute;
          top: 10px;
          right: 4px;
        }
        &:hover i {
          display: block;
        }
      }
      .add-btn {
        width: 59px;
        background: #ecf0ff;
        color: $blue;
        padding: 0;
        cursor: pointer;
        margin-bottom: 6px;
      }
    }
    .tips {
      line-height: 24px;
    }
    .red {
      color: #f5222d;
    }
    ::v-deep .f-start .el-form-item__error {
      display: none;
    }
  }
}
</style>
